body {
  font-family: Calibri, "Open Sans", sans-serif !important;
  font-size: 1rem !important;
  background-color: black !important;
}

.header {
  background-color: black !important;
  color: white !important;
  font-size: 1rem !important;
  height: 75px;
}

.content {
  position: relative;
  top: 75px;
  min-height: 500px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
}

.footer {
  position: relative;
  top: 75px;
  left: 0;
  width: 100%;
  height: auto;
  background-color: black;
  padding: 20px;
  margin-top: 20px;
  color: white !important;
  font-size: 0.8rem !important;
  border-top: 0px solid gray;
}

/* Style for navbar*/
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: red !important;
}

.dropdown-menu {
  border-radius: 0 !important;
  background-color: black !important;
  font-size: 0.8rem !important;
}

.dropdown-item {
  color: white !important;
}

.dropdown-item:hover {
  color: red !important;
  background-color: transparent !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: red !important;
  text-decoration: none;
  background-color: transparent !important;
}

.dropdown-divider {
  border-top: 1px solid white !important;
}

.navbar-dark .navbar-toggler-icon {
  display: none;
}

.navbar-collapse {
  background-color: black !important;
}

/* general styles */
.link {
  color: white;
  text-decoration: none;
}

.link:hover {
  color: red;
  text-decoration: none;
}

.active-link {
  color: red;
}

.white {
  color: white !important;
}

.channel-title h2 {
  font-size: 1.8rem;
}

.channel-description {
  padding-left: 40px;
}

.channelDiv {
  width: 25% !important;
}

.VODDiv,
.vodDiv {
  width: 16.6666% !important;
}

.VODPreviewImage {
  width: 50%;
  border-radius: 10px;
}

.swal2-container.swal2-center>.swal2-popup {
  /*border-radius: 0 !important;*/
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-deny {
  /*border-radius: 0 !important;*/
  background-color: red !important;
  height: 40px !important;
  border-radius: 25px !important;
  min-width: 200px !important;
}

.swal2-default-outline,
.swal2-styled {
  /*border-radius: 0 !important;*/
  min-width: 25% !important;
}

.swal2-styled.swal2-confirm:focus,
.swal2-styled.swal2-confirm:active,
.swal2-cancel:focus,
.swal2-cancel:active {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-actions button {
  background-color: red;
}

.image {
  border-radius: 10px;
}

.playerCloseBtn {
  position: fixed;
  top: 30px;
  left: 30px;
  font-size: 22pt;
  color: white;
  text-decoration: none;
}

.playerCloseBtn:hover {
  color: red;
}

/* form styles */
.form-div {
  position: relative;
  top: 0;
  left: 0%;
  width: 40%;
  height: auto;
  min-height: 300px;
  margin: auto;
  margin-top: 25px;
  background-color: transparent;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  z-index: 1;
  animation: 200ms ease-in 0s 1 normal forwards running fadeIn;
  border: 0px solid gray;
}

.form-div-2 {
  position: relative;
  top: 0;
  left: 0%;
  width: 40%;
  height: auto;
  min-height: 300px;
  margin: auto;
  margin-top: 0px;
  background-color: transparent;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  z-index: 1;
  animation: 200ms ease-in 0s 1 normal forwards running fadeIn;
  border: 0px solid gray;
}

select.form-control:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
  border: 0px solid gray;
  border-radius: 8px !important;
}

select.form-control,
textarea,
textarea.form-control,
input.form-control,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
[type="text"].form-control,
[type="password"].form-control,
[type="email"].form-control,
[type="tel"].form-control,
[contenteditable].form-control {
  border-radius: 8px !important;
  background-color: (232, 240, 254) !important;
  font-size: 12pt;
  height: 40px !important;
  border: 0px solid red;
}

.btn-primary0 {
  color: rgb(255, 255, 255) !important;
  min-height: 3rem !important;
  letter-spacing: 2px !important;
  text-transform: uppercase !important;
  background-color: rgb(255, 0, 0) !important;
  display: inline-block !important;
  border-radius: 0px !important;
  border: 1px solid rgb(255, 0, 0) !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  box-shadow: rgb(0 0 0 / 10%) 0px -3px 0px 0px inset !important;
}

.btn-primary {
  width: "100%" !important;
  height: 40px !important;
  border-radius: 25px !important;
  color: white !important;
  background-color: red !important;
  font-size: 12pt !important;
  border: 1px solid red !important;
  padding-top: auto !important;
}

.btn-primary:hover {
  background-color: darkred !important;
  border: 1px solid darkred !important;
}

.btn-primary:focus {
  box-shadow: inset 0 -1px 0 transparent !important;
}

.form-label {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.flag-dropdown,
.flag-dropdown:hover,
.flag-dropdown .open,
.selected-flag,
.selected-flag:hover {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.no-border {
  border: 0px solid white !important;
}
.pointer-cursor {
  cursor: pointer;
}

/* mobile styles */
@media (max-width: 768px) {
  #responsive-navbar-nav {
    position: fixed;
    top: 75px;
    left: 0;
    width: 100%;
    margin-left: 0px !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .header .container {
    background-color: black;
  }

  .content .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .form-div {
    position: relative;
    top: 0;
    left: 0%;
    width: 100%;
    height: auto;
    min-height: 300px;
    margin: auto;
    margin-top: 25px;
    background-color: transparent;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    z-index: 1;
    animation: 200ms ease-in 0s 1 normal forwards running fadeIn;
  }

  .channel-description {
    padding-left: 0px;
  }

  .w-md-50 {
    width: 100% !important;
  }

  .channelDiv,
  .vodDiv {
    width: 50% !important;
  }

  .VODDiv {
    width: 50% !important;
  }

  .VODPreviewImage {
    width: 90%;
    border-radius: 10px;
  }

  .form-div-2 {
    position: relative;
    top: 0;
    left: 0%;
    width: 100%;
  }
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #D12B1E;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.live-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  animation: blink 1s infinite;
  margin-top: 5px;
  margin-right: 5px;
  border: 0;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
